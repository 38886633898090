/**
 * Foursource page
 * https://foursource.com/code-of-conduct
 */

import * as React from 'react';
import { withTrans } from '../i18n/withTrans';

import { pageTexts } from '../content/code-of-conduct';

// components
import Layout from '../components/layout';
import Text from '../components/text';

class codeConductPage extends React.Component {
  constructor(props) {
    super(props);
    this.t = props.t;
    this.text = pageTexts(this.t);
  }

  render() {
    return (
      <Layout pageName='code-of-conduct' headerWhite={true}>
        <div className='pt-menu'>
          <div className='paddingSidesBig pb-75 pt-75'>
            <Text text={this.text.codeConduct}></Text>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withTrans(codeConductPage);
