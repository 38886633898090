export const pageTexts = (t) => {
  const NUMBER = 10; // 10 title + text on the Privacy Policy Page
  let termsText = [
    {
      text: t("code-of-conduct.agreeCode"),
      colorClass: "colorDark",
      extraClass: "font14 lineHeight1_7",
      textType: "paragraph",
    },
    {
      text: t("code-of-conduct.codeOfConduct"),
      colorClass: "colorDark",
      textType: "title",
      extraClass: "font26 text-center pt-40",
      heading: "h1",
    },
  ];

  for (let i = 1; i <= NUMBER; i++) {
    termsText.push({
      text: t("code-of-conduct." + i + "title"),
      colorClass: "colorDark",
      textType: "subtitle",
      extraClass: "font24 pt-20",
      heading: "h2",
    });
    termsText.push({
      text: t("code-of-conduct." + i + "text"),
      colorClass: "colorDark",
      extraClass: "font14 lineHeight1_7",
      textType: "paragraph",
    });
  }

  return {
    codeConduct: termsText,
  };
};
